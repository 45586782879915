@use 'styles/base/mixins';
@use 'styles/base/variables';
@use 'styles/base/fonts';

$iconCircleBorderColor: #008819;

div.campusIntroTextContainer {
  padding-left: 0;
  padding-right: 0;
}

.campusIntroTextNoTopMargin {
  margin-top: 0 !important;
}

.gddsHeadlineH1 {
  h1 {
    font-size: 1.75rem;
    line-height: 2.25rem;
    margin-bottom: 1.25rem;

    @include mixins.screen-sm {
      font-size: 2.25rem;
      line-height: 2.75rem;
    }

    @include mixins.screen-lg {
      font-size: 2.625rem;
      line-height: 3.25rem;
    }
  }
}

.gddsHeadlineH2 {
  font-size: 1.625rem;
  font-weight: variables.$font-weight-bold;
  margin-bottom: 2.5rem;
  margin-top: 3.563rem;
  line-height: 2rem;

  @include mixins.screen-md {
    margin-bottom: 3rem;
    margin-top: 4.563rem;
  }

  h2 {
    font-size: 1.625rem;
    line-height: 2rem;
    margin-bottom: 1.25rem;

    @include mixins.screen-sm {
      font-size: 1.75rem;
      line-height: 2.25rem;
    }

    @include mixins.screen-lg {
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }
}

.campusLayout {
  h1 {
    margin-bottom: 1.25rem;
  }
}

.campusMain {
  padding-bottom: 5rem;
  padding-top: 6.25rem;
}

.gddsHeadlineH3 {
  font-weight: variables.$font-weight-bold;
  text-transform: unset;
  font-size: 1.375rem;
  line-height: 1.75rem;
  margin-bottom: 2.5rem;

  @include mixins.screen-sm {
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 1.25rem;
  }

  @include mixins.screen-md {
    font-size: 1.625rem;
    line-height: 2rem;
  }
}

.descriptionHeadline {
  margin-bottom: 0;
}

.introText {
  margin-bottom: 4rem;

  p {
    width: 100%;
  }

  @include mixins.screen-md {
    margin-bottom: 5rem;

    p {
      width: 60%;
    }
  }
}

.errorPage {
  background-color: variables.$gb_grey_055;
}

.courseDetailsHeader {
  margin-top: 1rem;

  .learningForm {
    margin-top: 2rem;
    margin-bottom: 0.4rem;
  }

  .gddsHeadlineH1 {
    margin-top: 0.4rem;

    @include mixins.screen-lg {
      width: 50rem;
    }
  }

  .courseTeaser {
    margin-bottom: 4rem;

    @include mixins.screen-lg {
      margin-bottom: 5rem;
      width: 50rem;
    }
  }

  .gobackLinkTop {
    margin-bottom: 2rem;
  }
}

.courseDetailsDescription {
  margin-bottom: 2.75rem;
  margin-top: 4rem;

  @include mixins.screen-md {
    > div {
      margin: 0 12.75rem;
    }
  }

  @include mixins.screen-lg {
    margin-top: 5rem;
  }
}

.courseBookingTeaser {
  margin: 5.5rem 0 4rem 0;
}

.courseRegistrationBackLink {
  margin-top: 1rem;
}

.errorIcon > span,
.successIcon > span {
  display: inline-block;
  border-radius: 50%;
  padding: 1rem;
  margin-bottom: 1rem;
}

.mobileNotAvailable {
  text-align: center;
  margin: 4rem 0;

  .errorIcon > span {
    border: #da0012 3px solid;
  }

  > b {
    display: block;
    margin-bottom: 0.5rem;
  }

  > button {
    margin: 2rem 0;
    width: 100%;

    &::before {
      content: '';
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;
}

.bookingConfirmation,
.cancelConfirmation {
  .confirmation {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: 1.125rem;
    padding-bottom: 3.563rem;
    position: relative;
    text-align: center;
    width: 100%;
    margin: auto;

    @include mixins.screen-md {
      padding: 3.75rem 0 4.563rem 0;
      max-width: 36.75rem;
    }

    .successIcon > span {
      border: 3px solid $iconCircleBorderColor;
    }

    p {
      margin-bottom: 0;
      margin-top: 1rem;
    }

    .participantContainer {
      margin: 1.5rem 0;
      width: 100%;

      @include mixins.screen-md {
        margin-top: 3rem;
      }

      .participants {
        margin: 1rem 0;
      }
    }

    .buttonContainer {
      margin: 1rem 0;
      width: 100%;

      @include mixins.screen-sm {
        display: flex;
        justify-content: center;
        width: auto;
      }

      a {
        color: variables.$gb_white;
      }

      > button,
      > a {
        margin: 1rem;
        width: calc(100% - 2rem);

        @include mixins.screen-sm {
          width: auto;
        }

        &::before {
          content: '';
          display: none;
        }

        &:hover,
        &:active,
        &:focus {
          text-decoration: none;
        }
      }
    }

    .backToCourseButton {
      margin-top: 2rem;
    }
  }
}

.participantLogin {
  @include mixins.screen-md {
    display: flex;
    flex-direction: row-reverse;

    > * {
      width: 50%;
    }
  }

  .image {
    background-image: url('/images/campus-login.jpg');
    background-size: cover;
    background-position: center;
    margin-bottom: 0.5rem;

    @include mixins.screen-md {
      margin-bottom: 0;
    }
  }

  form {
    line-height: 1.5rem;
    border: 0 !important;
    margin: 0.5rem 1rem 1rem 1rem;

    @include mixins.screen-sm {
      max-width: 21.75rem;
      margin: auto auto 0.9375rem auto;
    }

    @include mixins.screen-md {
      max-width: 24rem;
      margin: auto auto auto 0.9375rem;
    }

    @media (min-width: calc(#{variables.$gb_breakpointLG} + 1.875rem)) {
      max-width: 24rem;
      margin: auto 13.5rem auto auto;
    }

    :global(.c-page-headline) {
      padding-left: 0;
    }

    h1 {
      margin-bottom: 2.75rem;
    }

    .welcomeText {
      margin-bottom: 2rem;
      font-weight: bold;
      font-size: 1rem;
    }

    .accessUntil {
      margin: 1.5rem 0 2rem 0;
    }

    .loginButton,
    .backButton {
      margin-top: 1.5rem;
    }
  }
}

.landingPage {
  .banner {
    background-image: url('/images/campus-login.jpg');
    background-size: cover;
    background-position: center;

    @include mixins.below-screen-sm {
      background-image: url('/images/campus-login-mobile.jpg');
      background-size: auto;
      height: 360px;
    }
  }

  .content {
    margin: 4.5rem auto;

    p {
      max-width: none;
    }
  }

  .buttonContainer {
    display: flex;

    a.loginButton {
      -webkit-appearance: none;
      width: auto;
      color: variables.$gb_white;

      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
      }

      &::before {
        content: '';
        display: none;
      }
    }
  }

  .pictos {
    margin-bottom: 3.75rem;

    img {
      width: 3.75rem;
      height: auto;
      align-self: flex-start;
    }

    :global(.cell) {
      display: flex;
      margin-bottom: 32px;

      @include mixins.screen-md {
        margin-bottom: 0;
      }

      > :first-child {
        margin-right: 1.25rem;
      }

      .benefitContent {
        b {
          margin-bottom: 0.5rem;
        }
      }
    }
    > :last-child {
      margin-bottom: 0;
    }
  }

  hr {
    margin: 5.5rem 0 3.75rem 0;
  }

  button[type='button'] {
    -webkit-appearance: none;
  }
}

.campusSearch {
  width: 17rem;
  margin: auto;
  margin-bottom: 2.5rem;

  @include mixins.screen-md {
    margin-bottom: 3rem;
  }

  form {
    all: unset;
  }

  form input[type='text'] {
    padding: 0 0.5rem;
    border: 1px solid transparent;
    border-bottom: 1px solid variables.$gb_gdds_cl10b;
    width: auto;

    &:hover {
      box-shadow: none;
      border-bottom: 1px solid #000000;
    }

    &:focus,
    &:active {
      box-shadow: 0 1px 0 #000000;
      border-bottom: 1px solid #000000;
    }

    &::placeholder {
      color: variables.$color-black-36;
    }
  }
}

.courseContentFrame {
  min-height: 675px;
  margin: 60px 0;
  position: relative;
  display: flex;

  iframe {
    width: 100%;
    height: 100%;
  }
}

.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

button,
a {
  & > span:global(.c-tpp-area) {
    height: auto;
  }
}

.campusBannerWrapper {
  max-height: 100vh;
  overflow: hidden;
  margin-top: variables.$header-height-mobile;
  @include mixins.screen-md {
    margin-top: 0;
  }
}
